<template>
  <div  class="gen-button">
    <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
    <polygon  points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69"/>
    <path fill="none" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"/>
    </svg> 
  </div>
</template>

<script>
export default {
   name: 'SVGPlay'
}
</script>

<style scoped>
.play-btn {
  width: 25%;
  height: auto;
  margin: 0 auto;
  margin-top: 10%;
}

.play-btn__svg{
  transition: 1s; 
  fill:#7c7c7c; 
  opacity: .5;
}

.play-btn:hover .play-btn__svg {
  fill: #a41d33;
  opacity: 1;
}
</style>